import '/styles/globals.scss';
import '@aws-amplify/ui-react/styles.css';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'nprogress/nprogress.css';
import 'react-toastify/dist/ReactToastify.css';

//@ts-ignore
import NProgress from 'nprogress';
import Script from 'next/script';
import poweredBy from '../assets/poweredbyfooter-min.jpg';
import Head from 'next/head';
import Session from 'supertokens-auth-react/recipe/session';
import useScrollDirection from '../utils/useScrollDirection';

import * as fbq from '../utils/fpixel';
import * as Sentry from '@sentry/nextjs';

import type { AppProps } from 'next/app';
import { Image } from '@aws-amplify/ui-react';
import { Router, useRouter } from 'next/router';
import { nunito, righteous } from '../utils/fonts';
import { useEffect, useState } from 'react';
import { frontendConfig } from '../config/frontendConfig';
import SuperTokensReact, { SuperTokensWrapper } from 'supertokens-auth-react';
import { captureException } from '../utils/commons';

if (typeof window !== 'undefined') {
  // we only want to call this init function on the frontend, so we check typeof window !== 'undefined'
  SuperTokensReact.init(frontendConfig());
}

NProgress.configure({ showSpinner: false, easing: 'ease', speed: 300 });

Router.events.on('routeChangeStart', () => {
  NProgress.start();
});
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

function App({ Component, pageProps }: AppProps) {
  const router = useRouter();
  const scrollDirection = useScrollDirection();
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    // For general errors
    window.onerror = function(msg, url, lineNo, columnNo, error) {
      //console.log('window.onerror triggered:', { msg, url, lineNo, columnNo, error });

      const errorToReport = error || new Error(typeof msg === 'string' ? msg : 'Unknown error');
      captureException(errorToReport, {
        extra: {
          url,
          lineNo,
          columnNo,
          type: 'window.onerror'
        }
      });
    };

    // For script loading errors
    const handleScriptError = function(event: Event) {
      // Check if the error was from loading a script
      if (event.target instanceof HTMLScriptElement) {
        console.log('Script load error:', {
          src: event.target.src
        });

        captureException(new Error(`Script load failed: ${event.target.src}`), {
          extra: {
            scriptSrc: event.target.src,
            type: 'script_load_error'
          }
        });
      }
    };

    window.addEventListener('error', handleScriptError, true);

    const handleUnhandledRejection = function(event: PromiseRejectionEvent) {
      const errorDetails = {
        message: event.reason?.message || 'Promise rejection',
        stack: event.reason?.stack || 'No stack trace available',
        userAgent: window.navigator.userAgent,
        page: window.location.pathname
      };

      // Send to Sentry
      captureException(event.reason, {
        extra: errorDetails
      });
    };

    window.addEventListener('unhandledrejection', handleUnhandledRejection);

    return () => {
      window.onerror = null;
      window.removeEventListener('unhandledrejection', handleUnhandledRejection);
      window.removeEventListener('error', handleScriptError, true);
    };
  }, []);

  useEffect(() => {
    async function doRefresh() {
      if (pageProps.fromSupertokens === 'needs-refresh') {
        if (await Session.attemptRefreshingSession()) {
          location.reload();
        } else {
          // user has been logged out
          SuperTokensReact.redirectToAuth();
        }
      }
    }
    doRefresh();
  }, [pageProps.fromSupertokens]);

  useEffect(() => {
    // This pageview only triggers the first time (it's important for Pixel to have real information)
    fbq.pageview();

    const handleRouteChange = () => {
      fbq.pageview();
    };

    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  //scroll tracker to see whether we should show or hide the sticky "powered by" footer
  useEffect(() => {
    setIsVisible(scrollDirection === 'up');
  }, [scrollDirection]);

  if (pageProps.fromSupertokens === 'needs-refresh') {
    return null;
  }

  return (
    <>
      <Head>
        <title>Sampoll - Discover New Food & Beverage Products Near You</title>
      </Head>
      <main className={nunito.className}>
        <style jsx global>{`
          html {
            font-family: ${nunito.style.fontFamily};
          }
          h1,
          h2 {
            font-family: ${righteous.style.fontFamily};
          }
        `}</style>
        <Script
          src={`https://maps.googleapis.com/maps/api/js?key=${process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY}`}
          async
          defer
        />
        {/* Commenting out MS Clarity for now - GA seems to capture most of what we need. Just turn this on if we start seeing UX issues that we cant debug otherwise
        <Script id="ms-clarity" strategy="afterInteractive">
          {`
          (function(c,l,a,r,i,t,y){
          c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
          t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
          y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
          })(window, document, "clarity", "script", "hamhf2wxn3");
        `}
        </Script>*/}
        {/* Global Site Code Pixel - Facebook Pixel */}
        <Script
          id="fb-pixel"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', ${fbq.FB_PIXEL_ID});
          `,
          }}
        />
        {/* GA4 Scripts */}
        <Script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=G-JYR920CE6J`}
          strategy="afterInteractive"
        ></Script>
        <Script id="google-analytics" strategy="afterInteractive">
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-JYR920CE6J');
        `}
        </Script>

        <SuperTokensWrapper>
          <Component {...pageProps} />
        </SuperTokensWrapper>
        {
          <div className={`stickyfooter_image ${isVisible ? 'visible' : 'hidden'}`}>
            <Image className={'poweredby_image'} src={poweredBy.src} alt={'Background Image'} />
          </div>
        }
      </main>
    </>
  );
}

export default App;
